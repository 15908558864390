<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='mailListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <!-- ID -->
              <div>
                <div class="md-form mb-0">
                  <input id="mail_id" name="mail_id" ref="mail_id"
                         :title="this.$i18n.t('form.email.id')" class="md-form-control" type="text"
                         :value="filters.filMailId">
                  <label :class="{'active': filters.filMailId}">{{ $t('form.mail.id') }}</label>
                </div>
              </div>

              <h6 class="mb-0 mt-4">{{ $t('form.mail.toSendDate') }}</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form mb-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="to_send_date_begin" ref="to_send_date_begin" readonly
                               class="form-date md-form-control date-filter" type="text"
                               :value="$util.formatDate(filters.filPeriodoToSendDtInicio, undefined,null)">
                        <label :class="{'active': filters.filPeriodoToSendDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="to_send_date_end" ref="to_send_date_end" readonly class="form-date md-form-control date-filter"
                               type="text" :value="$util.formatDate(filters.filPeriodoToSendDtFim, undefined, null)">
                        <label :class="{'active': filters.filPeriodoToSendDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"
                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 0 }"
                     v-on:click="applyStatusFilter(0)" data-toggle="tab">{{ $t('form.request-access.statusList.pending') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.request-access.statusList.accepted')
                    }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 2 }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('form.request-access.statusList.refused')
                    }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList"/>
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <!--<div v-on:click="toggleColumn">Config</div>-->
                <div class="table-responsive ">
                  <vuetable ref="vuetable"
                            :noDataTemplate="$t('noting to result')"
                            :api-url="listEndpoint"
                            :fields="fieldsTable"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="20"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="id"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-error="handleLoadError"
                            @vuetable:cell-clicked="onRowClicked">
                    <div slot="fluxo_aprovacao-slot" slot-scope="props">
                      <span :style="'color:' + formatColorFluxoAprovacao(props.rowData.fluxo_aprovacao)">
                        {{  formatLabelFluxoAprovacao(props.rowData.fluxo_aprovacao)  }}
                        <span> <i class="ft-alert-circle"></i><md-tooltip md-direction="right">
                          {{  formatDescriptionFluxoAprovacao(props.rowData.fluxo_aprovacao)  }}</md-tooltip>
                        </span>
                      </span>
                    </div>
                    <template slot="actions" slot-scope="props">
                      <div class="custom-actions">
                        <a class="btn btn-link btn-padding" :title="$t('actions.edit')"
                           v-bind:href="mountLink('RequestAccessValidation', props.rowData)">
                          <i class="text-primary  ti-pencil font-20"></i>
                        </a>
                      </div>
                    </template>
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import dayjs from 'dayjs'

// Components

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import MailService from '@/services/MailService'

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.mail-status') + ' - %s'
    }
  },
  data () {
    return {
      mailTypeList: [],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      listEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/solicitacao-acesso',
      multiSort: true,
      activeFilterList: {
        id_status: null,
        to_send_date_begin: null,
        to_send_date_end: null,
        mail_id: null
      },
      filter: {},
      fieldsTable: [
        {
          name: 'id',
          title: this.$i18n.t('form.request-access.id'),
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('form.request-access.nome'),
          sortField: 'nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'email',
          title: this.$i18n.t('form.request-access.email'),
          sortField: 'email',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome_fantasia',
          title: this.$i18n.t('form.request-access.nome_fantasia'),
          sortField: 'nomeFantasia',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'id_responsavel.email',
          title: this.$i18n.t('form.request-access.id_responsavel'),
          sortField: 'p.email',
          formatter: (value) => this.$util.formatUser(value)
        },
        {
          name: 'data_solicitacao',
          title: this.$i18n.t('form.request-access.data_solicitacao'),
          sortField: 'dataSolicitacao',
          formatter: (value) => this.formatDateTime(value)
        },
        {
          name: 'status',
          title: this.$i18n.t('form.request-access.status'),
          sortField: 'status',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'fluxo_aprovacao-slot',
          title: this.$i18n.t('form.request-access.fluxoAprovacao'),
          sortField: 'fluxo_aprovacao'
          // formatter: (value) => this.formatFluxoAprovacao(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          width: '200px',
          dataClass: 'text-center',
          titleClass: 'text-center'
        }
      ],
      sortOrder: [
        { field: 'id', direction: 'DESC' }
      ],
      fluxoAprovacaoList: [
        {
          'id': 1,
          'label': this.$i18n.t('form.request-access.fluxoAprovacaoList.novo_prospecto'),
          'description': this.$i18n.t('form.request-access.fluxoAprovacaoList.novo_prospecto_descricao'),
          'color': '#ababab'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.request-access.fluxoAprovacaoList.novo_contato_prospecto'),
          'description': this.$i18n.t('form.request-access.fluxoAprovacaoList.novo_contato_prospecto_descricao'),
          'color': '#5ec65f'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.request-access.fluxoAprovacaoList.solicitacao_acesso'),
          'description': this.$i18n.t('form.request-access.fluxoAprovacaoList.solicitacao_acesso_descricao'),
          'color': '#5ec65f'
        }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    Loading,
    FilterContainer
  },
  created () {
    let _this = this
    // Load basilares data
    MailService.getMailTypes().then(res => {
      _this.mailTypeList = res.data.data
    })
  },
  // Apply filters to local filter
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('.date-filter').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { token: object.request_token } }).href
    },
    buildFilters () {
      this.filter.to_send_date_begin = this.filters.filPeriodoToSendDtInicio
      this.filter.to_send_date_end = this.filters.filPeriodoToSendDtFim

      this.filter.mail_id = (this.filters.filMailId) ? this.filters.filMailId : null

      this.filter.id_status = null
    },
    handleLoadError (response) {
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    // Loader
    applyFilter () {
      let dayParse = 'DD/MM/YYYY'

      this.filters.filPeriodoToSendDtInicio = dayjs(this.$refs.to_send_date_begin.value, dayParse).isValid() ? dayjs(this.$refs.to_send_date_begin.value, dayParse).toISOString() : null
      this.filters.filPeriodoToSendDtFim = dayjs(this.$refs.to_send_date_end.value, dayParse).isValid() ? dayjs(this.$refs.to_send_date_end.value, dayParse).toISOString() : null

      this.filters.filMailId = (this.$refs.mail_id.value) ? (this.$refs.mail_id.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()

      this.filters.filPeriodoToSendDtInicio = null
      this.filters.filPeriodoToSendDtFim = null

      this.filters.filMailId = null

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value) {
      if (value !== 0 && !value) {
        return ' - '
      }

      let statusList = [
        {
          'id': 0,
          'label': this.$i18n.t('form.request-access.statusList.pending'),
          'color': '#ababab'
        },
        {
          'id': 1,
          'label': this.$i18n.t('form.request-access.statusList.accepted'),
          'color': '#5ec65f'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.request-access.statusList.refused'),
          'color': '#e04646'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    findFluxoAprovacao (value) {
      return this.fluxoAprovacaoList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })
    },
    formatColorFluxoAprovacao (value) {
      if (value !== 0 && !value) {
        return ''
      }
      let fluxoAprovacao = this.findFluxoAprovacao(value)
      return fluxoAprovacao.color
    },
    formatLabelFluxoAprovacao (value) {
      if (value !== 0 && !value) {
        return ' - '
      }
      let fluxoAprovacao = this.findFluxoAprovacao(value)
      return fluxoAprovacao.label
    },
    formatDescriptionFluxoAprovacao (value) {
      if (value !== 0 && !value) {
        return 'Sem descrição'
      }
      let fluxoAprovacao = this.findFluxoAprovacao(value)
      return fluxoAprovacao.description
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()
    },
    onRowClicked (row) {
      this.$router.push({ name: 'RequestAccessValidation', params: { token: row.data.request_token } })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return this.$store.getters.aplliedFilters || null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .btn-padding {
    padding: 0.55rem 0.30rem
  }
</style>
